.wrapper {
  @apply w-full bg-darkNevada h-[439px] pt-10;
}

.title {
  @apply font-bold text-[26px] pb-5 text-white;
}

.badges {
  @apply flex absolute z-10 left-2 top-2;
}
