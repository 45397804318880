.tabList {
  @apply border-b border-alto;
}

.tabPanels {
  @apply mt-[25px];
}

.tab {
  @apply rounded-t font-os focus:outline-none h-[39px] p-[7.5px_20px] text-sm;
}

.tabselected {
  @apply border-b-2 border-apple-light;
  text-shadow: 0 0 1px black;
}

.tabnotselected {
  @apply font-normal;
}

.tabPanelWrap {
  @apply hidden;
}

.tabPanelWrapVisible {
  @apply block;
}
