.wrapper {
  @apply w-full py-10;
}

.sectionTitle {
  @apply font-bold text-[26px] pb-5;
}

.titleWrapper {
  @apply flex items-center pb-3;
}

.badge {
  @apply pt-[2px] px-3;
  animation: breathing 3s ease-out infinite normal;
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}

.title {
  @apply font-bold text-xl;
}

.subTitle {
  @apply text-base pb-3;
}

.columns {
  @apply grid gap-3;
  grid-template-columns: 1fr 1fr;
}

.headColumn {
  grid-column: 1 / -1;
}

.headColumn:nth-child(even) {
  @apply flex-row-reverse;
}

.headColumn:nth-child(even) .youtubeVideoThumbnail {
  @apply pl-[0] pr-[25px];
}

.columnWrapper {
  @apply flex items-center rounded-sm bg-white;
}

.logoWrapper {
  @apply border-[1px] border-r-0 border-alto h-[100%] flex items-center justify-center min-w-[185px];
}

.infoWrapper {
  @apply p-[12px_20px_15px] border-[1px] border-alto w-[100%] h-[100%];
}

.buttonWrapper button {
  @apply w-max px-[25px];
}

/* Top Head Column */

.headColumn {
  @apply flex items-center p-[25px] border border-alto bg-white;
}

.headColumnInfoLeft {
  @apply w-[50%];
}

.headColumnIcon {
  @apply mb-5;
}

.youtubeVideoThumbnail {
  @apply cursor-pointer pl-[25px];
}

.iframeWrapper {
  @apply w-[1000px] h-[600px] relative;
}

.iframeWrapper iframe {
  @apply w-full h-full p-[50px_0_0];
}

.searchWrap {
  @apply flex flex-col pt-[4px];
}

.searchLabel {
  @apply block text-standarts-black text-[12px] font-semibold pb-[5px];
}

.searchInner {
  @apply flex flex-row;
  button {
    width: 157px;
  }
}

.searchInner .inputWrapper {
  @apply pr-[12px];
  width: calc(100% - 157px); /* 157 => button width */
}
