.wrapper {
  @apply rounded border border-alto block w-[262px] h-[308px];
}

.wrapperForEmlakjetLink {
  @apply w-[240px] h-[280px];
}

.wrapperForCompanyDetail {
  @apply w-[240px] h-[275px];
}

.wrapperForProjectDetail {
  @apply w-[218px] h-[275px];
}

.imageWrapper {
  @apply relative h-[153px];
}

.commissionRate {
  @apply absolute top-[7px] left-[7px] bg-white text-xs px-2 py-1 rounded-[3px] text-xs text-apple-dark font-bold;
}

.imageClass {
  @apply w-[260px] h-[153px] object-cover;
  border-radius: 3px 3px 0 0;
}

.contentWrapper {
  @apply bg-white flex-1 px-[15px] pt-[15px] relative rounded-[3px] h-[152px];
}

.contentWrapperForCompanyDetail {
  @apply h-[120px] p-[10px];
}

.title {
  @apply text-[13px] font-semibold;
}

.hiddenOverflow {
  @apply whitespace-nowrap overflow-hidden text-ellipsis pt-[3px];
}

.phoneWrapper {
  @apply absolute bottom-[-3px];
}

.quickinfo {
  @apply text-xs whitespace-nowrap;
}

.locationWrapper {
  @apply flex items-center text-xs;
}

.location {
  @apply ml-[4px];
}

.learnPrice {
  @apply font-bold text-sm text-apple-light leading-[30px];
}

.price {
  @apply font-bold text-lg;
}

.previousPrice {
  @apply text-gray line-through text-xs;
}

.meterPrice {
  @apply text-xs;
}

.badges {
  @apply flex absolute z-10 left-[8px] top-[8px];
}

.buttonWrapper {
  @apply w-[91px];
}

.buttonClass {
  @apply font-bold border-none text-left hover:border-none text-apple-light bg-transparent text-sm;
}

.favoriteIcon {
  @apply flex absolute z-10 right-[25px] top-2;
}

.projectFinished {
  @apply flex items-center mt-2;
}

.projectFinishedForCompanyDetail {
  @apply mt-2;
}

.finishedTitle {
  @apply font-semibold text-sm ml-[6px];
}

.startCount {
  @apply text-[13px];
}

.companyDetailPrice {
  @apply text-[14px];
}

.roomTypes {
  @apply text-[12px]  flex my-[1px] text-ellipsis whitespace-nowrap;
}

.landAndWorkPlaceText {
  @apply text-[12px]  flex my-[1px] text-ellipsis whitespace-nowrap overflow-hidden;
}


.roomTypesToolTip::after {
  @apply w-[160px] z-[5000];
}

.isCompanyDetailclasses {
  @apply flex items-center my-[5px] min-h-[20px] text-ellipsis;
}

.projectDeliveryTime {
  @apply text-xs text-gray;
}

.divider {
  @apply text-xs text-gray mx-[5px];
}

.spacer{
  @apply pb-[19px]
}