.searchbutton {
  width: 97px;
  height: 50px;
}

.tabpanel {
  @apply h-full flex;
}

.typeinput {
  width: 160px;
  height: 50px;
}

.locationinput {
  @apply border border-alto rounded flex items-center;
  padding-left: 10px;
  padding-right: 10px;
  height: 50px;
}

.locationinputWidth {
  width: 321px;
}

.roominput {
  width: 145px;
  height: 50px;
}

.priceinput {
  width: 191px;
  height: 50px;
}

.title {
  @apply font-semibold;
  font-size: 14px;
}

.field {
  margin-right: 12px;
}

.wfull {
  @apply w-full;
}

.list {
  width: 160px;
  height: 312px;
}

.checkboxwidth {
  width: 145px;
  height: 195px;
}

.rangeWidth {
  width: 360px;
  height: 271px;
}

.mapButtonClass {
  @apply ml-[3px] font-bold !important;
}

.mapButtonClass:hover {
  @apply text-apple-light;
}

.mapButtonClass:focus,
.mapButtonClass:active {
  @apply outline-none border-transparent;
  transition: none;
}

.inputWrapper {
  @apply w-full;
}

.inputClass {
  @apply border-none;
}

.buttonWrapper {
  height: 30px !important;
  width: 50%;
}

.buttonClass {
  @apply font-semibold text-nevada whitespace-nowrap flex items-center;
  background-color: #eef8ec !important;
  color: #5f787e !important;
  font-weight: 600 !important;
  border: 1px solid theme("colors.moss-green") !important;
  font-size: 13px !important;
  padding-left: 15px;
  padding-right: 15px;
}

.buttonClass:hover {
  background-color: #eef8ec !important;
}

.icon {
  margin-left: 4px;
}

.poiButtonText {
  @apply whitespace-nowrap overflow-hidden text-ellipsis;
  max-width: 83%;
}

.landForFlat {
  width: 519px;
}
