@keyframes dotsAnimation {
  0% {
    content: "";
  }
  10% {
    content: ".";
  }
  20% {
    content: "..";
  }
  30% {
    content: "...";
  }
  40% {
    content: "....";
  }
  50% {
    content: ".....";
  }
  60% {
    content: "......";
  }
  70% {
    content: ".......";
  }
  80% {
    content: "........";
  }
  90% {
    content: ".........";
  }
  100% {
    content: "..........";
  }
}

.wrapper {
  @apply w-full h-[533px] pt-10;
}

.tabWrapper {
  @apply mt-[24px];
}

.title {
  @apply font-bold text-[26px];
}

.loaderWrapper {
  @apply w-full h-[233px] flex justify-center items-center  absolute;
}

.isLoading {
  @apply opacity-30;
}

.loadingTitle::after {
  content: "";
  display: inline-block;
  animation: dotsAnimation 1.5s steps(3, end) infinite;
}
