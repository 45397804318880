.searchbutton {
  @apply w-[97px] h-[50px];
}

.tabpanel {
  @apply h-full flex;
}

.typeinput {
  @apply w-[160px] h-[50px];
}

.locationinput {
  @apply border border-alto rounded flex items-center px-[10px] h-[50px];
}

.locationinputWidth {
  @apply w-[321px];
}

.roominput {
  @apply w-[145px] h-[50px];
}

.priceinput {
  @apply w-[191px] h-[50px];
}

.title {
  @apply font-semibold text-[14px];
}

.field {
  @apply mr-[12px];
}

.wfull {
  @apply w-full;
}

.list {
  @apply w-[160px] h-[122px];
}

.checkboxwidth {
  @apply w-[145px] h-[195px];
}

.rangeWidth {
  @apply w-[360px] h-[271px];
}

.mapButtonClass {
  @apply ml-[3px] font-bold !important;
}

.mapButtonClass:hover {
  @apply text-apple-light;
}

.mapButtonClass:focus,
.mapButtonClass:active {
  @apply outline-none border-transparent transition-none;
}

.inputWrapper {
  @apply w-full;
}

.inputClass {
  @apply border-none;
}

.buttonWrapper {
  @apply h-[30px] w-[50%] !important;
}

.buttonClass {
  @apply font-semibold text-nevada whitespace-nowrap flex items-center px-[15px] bg-[#eef8ec] text-[#5f787e] font-semibold border border-moss-green text-[13px] !important;
}

.buttonClass:hover {
  @apply bg-[#eef8ec] !important;
}

.icon {
  @apply ml-1;
}

.poiButtonText {
  @apply whitespace-nowrap overflow-hidden text-ellipsis max-w-[83%];
}

.landForFlat {
  @apply w-[478px];
}
