.dropdown {
  @apply relative w-full h-full cursor-default rounded bg-white text-left border  border-alto font-os overflow-hidden flex items-center justify-between text-[15px] pl-[15px];
}

.list {
  @apply absolute mt-1 max-h-60  z-50 overflow-auto rounded bg-white border  border-alto font-os text-sm;
}

.icon {
  @apply absolute right-[3px];
}

.buttoncontent {
  @apply whitespace-nowrap overflow-hidden text-ellipsis w-[90%];
}

.optionpadding {
  @apply flex items-start flex-col py-[10px] px-[15px];
}

.optionselected {
  @apply bg-wild-sand;
}

.placeholder {
  @apply text-gray font-os text-sm;
}

.dropdown[data-headlessui-state="open"] > .icon {
  @apply absolute rotate-180 right-[3px];
}

.dropdown:disabled,
.dropdown[disabled] {
  @apply bg-wild-sand;
}

.childClass {
  @apply ml-[15px] mt-[6px];
}

.parentClass {
  @apply font-semibold;
}

.filterInput {
  @apply ml-[15px] mt-[10px] w-[195px] h-10;
}

.dividerContainer {
  background-color: #d8d8d8;
  height: 1px;
  margin: 0 auto;
  width: 174px;
  display: block;
}
