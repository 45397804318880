.searchbutton {
  width: 97px;
  height: 50px;
}

.tabpanel {
  @apply h-full flex;
}

.input {
  width: 953px;
  height: 50px;
}

.title {
  @apply font-semibold;
  font-size: 14px;
}

.field {
  margin-right: 12px;
}
.suggestionClass {
  width: 953px !important;
}

.dangerMessage {
  @apply bg-fantasy border border-carnation absolute;
  margin-left: 8px;
  padding: 10px;
  font-size: 12px;
}

.dangerMessage::before {
  @apply absolute;
  border: 10px solid transparent !important;
  bottom: 100%;
  border-top-width: 0 !important;
  border-bottom-color: theme("colors.carnation") !important;
  z-index: 1001;
  content: "";
}
