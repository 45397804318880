.wrapper {
  @apply p-[40px_0] bg-wild-sand;
}

.header {
  @apply flex flex-row justify-between pb-[25px];
}

.title {
  @apply font-bold text-standarts-black text-[26px] leading-[35px];
}

.link {
  @apply flex items-center font-bold text-apple-light text-sm leading-[19px];
  transition: all 0.2s ease-in-out;
}

.link:hover {
  @apply text-apple-dark;
}

.list {
  @apply grid grid-cols-2 gap-list-space;
}
