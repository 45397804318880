.titleWrapper {
  @apply flex justify-between mb-[20px];
}

.title {
  @apply text-base;
}

.buttonWrapper {
  @apply mr-3 w-[88px] h-[19px];
}

.buttonClass {
  @apply font-bold border-none text-apple-light bg-transparent text-sm;
}

.icon {
  @apply ml-[3px];
}
