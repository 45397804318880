.wrapper {
  @apply p-[40px_0];
}

.title {
  @apply text-standarts-black font-bold text-[26px] leading-[35px];
}

.list {
  @apply flex flex-row pt-[25px];
}

.list > li + li {
  @apply ml-[10px];
}

.list a {
  @apply font-semibold text-standarts-black bg-standarts-white border-solid border-alto border text-sm leading-[19px] p-[10px_15px] rounded-[42px];
}
