.wrapper {
  @apply flex w-full items-center h-[380px] pl-[10px] pr-[10px];
}

.inner {
  @apply w-full columns-1 min-h-[300px];
}

.title {
  @apply w-full text-center text-[36px] leading-[49px] font-bold pb-[40px] text-standarts-white;
}

.buttonwrapper {
  @apply flex mt-[15px];
}

.btnwidth {
  @apply h-9 mr-[10px];
}

.btn {
  @apply flex items-center justify-center font-semibold border-none text-xs py-0 px-3 text-nevada;
}

.buttontitle {
  @apply ml-[6px];
}

.showcaseImageWrapper {
  @apply absolute;
}

.showcaseImage {
  @apply w-screen h-[380px] -z-10;
}
