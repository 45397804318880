.dropdown {
  @apply relative w-full h-full cursor-default rounded bg-white text-left border  border-alto font-os flex items-center justify-between;
  font-size: 15px;
  padding-left: 15px;
}

.listwrapper {
  @apply absolute mt-1 max-h-60 z-50 overflow-hidden rounded bg-white border  border-alto font-os flex;
  font-size: 14px;
}

.list {
  @apply bg-white overflow-auto;
}

.borderright {
  @apply border-r border-alto;
}

.option {
  @apply hover:bg-alto font-normal;
  padding: 10px 15px;
  font-size: 14px;
}

.inputwrapper {
  @apply sticky top-0 flex flex-col bg-white;
  padding: 15px 15px 0 15px;
}
.inputtitle {
  @apply font-semibold;
  font-size: 12px;
}

.inputTypeWrapper {
  @apply flex border border-alto rounded;
}

.wildsand {
  @apply bg-wild-sand;
}

.icon {
  @apply inset-y-0 right-0 flex items-center pr-3;
}

.placeholder {
  @apply text-gray font-os;
  font-size: 15px;
}

.dropdown[data-headlessui-state="open"] > .icon {
  @apply inset-y-0 right-0 flex items-center pl-3 rotate-180;
}

.buttoncontent {
  @apply whitespace-nowrap overflow-hidden text-ellipsis;
}

.input {
  @apply border-none;
}

.inputType {
  @apply flex items-center rounded-r bg-white text-left  text-gray font-os;
  padding-right: 10px;
  font-size: 14px;
}
