@tailwind components;
.wrapper {
  @apply flex flex-col;
}

.header {
  @apply flex flex-row;
}

.title {
  @apply flex items-center justify-center;
  font-size: 14px;
  line-height: 19px;
  padding: 0 20px;
  height: 39px;
  color: theme("colors.standarts.black");
  background-color: theme("colors.peppermint");
}

.tabpanel {
  @apply rounded-b rounded-tr bg-white;
  height: 120px;
  padding: 25px 20px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.tab {
  @apply rounded-t font-os focus:outline-none;
  height: 39px;
  padding: 10px 20px;
  margin-right: 8px;
  font-size: 14px;
}

.tabselected {
  text-shadow: 0 0 1px black;
  background-color: theme("colors.white") !important;
}

.tabnotselected {
  @apply font-normal;
  background-color: theme("colors.peppermint") !important;
}

.wfull {
  @apply w-full;
}
