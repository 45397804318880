.root {
  &.primary {
    :global {
      .swiper-button-prev,
      .swiper-button-next {
        @apply rounded bg-black opacity-50 h-[35px] w-[35px];
      }
      .swiper-button-prev {
        @apply left-[3px];
      }
      .swiper-button-next {
        @apply right-[19px];
      }

      .swiper-button-prev::after,
      .swiper-button-next::after {
        @apply font-bold text-white text-sm;
      }
      .swiper {
        height: max-content;
      }
    }
  }
}
