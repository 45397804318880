.wrapper {
  @apply bg-peppermint h-[344px] p-[50px_0_0];
}

.inner {
  @apply flex flex-row justify-between;
}

.content {
  @apply max-w-[525px] pt-5;
}

.title {
  @apply text-standarts-black font-bold text-[26px] leading-[35px];
}

.info {
  @apply text-standarts-black text-base leading-[25px] pt-5;
}

.buttonWrap {
  @apply flex flex-row pt-[35px];
}

.buttonWrap > * {
  @apply mr-4;
}
