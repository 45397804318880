.wrapper {
  @apply flex flex-row bg-standarts-white rounded-[3px];
}

.media {
  @apply bg-alto flex items-center justify-center object-cover w-[262px] h-[148px] object-top;
}

.content {
  @apply border border-solid border-l-0 border-alto overflow-hidden p-[15px_15px_0];
  width: calc(100% - 262px);
}

.content > * {
  @apply block;
}

.title {
  @apply text-standarts-black font-bold text-[16px] leading-[23px] max-h-[46px];
}

.info {
  @apply text-standarts-black text-sm leading-[21px] pt-[6px] max-h-12;
}

.title,
.info {
  @apply overflow-hidden max-w-[251px];
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.date {
  @apply block font-semibold text-gray text-xs pt-[10px];
}
